.bulk-upload-form {
  .bulk-upload {
    margin-right: 24px;

    label {
      transform: translate(12px, 10px) scale(0.75);
    }
  }
}

.MuiInputLabel {
  .filled {
    font-size: "14px";
  }
}
