.d-flex {
    display: flex;
}

.jc-center {
    justify-content: center;
}

.mt-1 {
    margin-top: 1em !important;
}


.width-100 {
    width: 100% !important;
}

.align-center {
    align-items:center;
}

.padding-left {
    padding-left: 2em;
}

.display-none{
    display:none;
}