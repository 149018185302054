.textColor{
    color:red;
}
.feedback-textarea {
    width: 40%;
  
    height: 100px !important;
}
.mlfbInput {
   /*-*/
    width: 50%;
    margin: auto;

}
.border {
    border: 2px solid #008AA6;
    border-radius: 10px;
}
.uploadPadding {
    padding: 10px;
}
.staticTextPadding {
    padding-left: 5px;
    padding-top: 5px;
    height: auto;
}
.padding5px{
    padding:10px;
}

.tableHeaderStyle {
    height:100px;
}
.paddingLeftRight{
    padding-left:10px;
    padding-right:10px;
}
.clr-red {
    color:red;
}
.note-tag {
    font-size: 12px;
    font-style: italic;
    text-decoration: underline;
}