$mobile: 320px;
$mediumTablet: 767px;
$largeTablet: 1024px;
$desktop: 1399px;

.d-flex {
  display: flex;
}
.jc-center {
  justify-content: center;
}
.jc-flex-start {
  justify-content: flex-start;
}
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 1em !important;
}
.mt-3 {
  margin-top: 3em;
}
.mb-1 {
  margin-bottom: 1em !important;
}
.width-100 {
  width: 100%;
}
.bdr-dashed {
  border-bottom: 1px dashed #00bcbc;
}
.react-swipeable-view-container > div {
  overflow: hidden !important;
}
.MuiStepper-root {
  padding: 0 24px !important;
}
.MuiFormGroup-row {
  height: 28px;
  align-content: center;
}
.MuiStepIcon-active {
  border: 3px dashed #00bcbc;
}
.MuiStepConnector-line {
  border-color: #00bedc !important;
}
.align-center {
  align-items: center !important;
}
