$mobile: 320px;
$mediumTablet: 767px;
$largeTablet: 1024px;
$desktop: 1399px;
$largeScreen: 1980px;

.master-cognisphere {
  position: fixed;
  top: -40vh;
  right: -70vw;

  @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
    display: block;
    position: fixed;
    left: -80vw;
    top: -10vh;
  }

  @media screen and (min-width: $mediumTablet) and (max-width: $desktop) {
    display: block;
    position: fixed;
    right: -90vw;
    bottom: -22vh;
  }

  @media screen and (min-width: $desktop) and (max-width: $largeScreen) {
    display: block;
    position: fixed;
    right: -45vw;
    bottom: -22vh;
  }
}

.menu-item-container {
  position: relative;
  display: flex;
  align-items: center;
  background: var(--landing-page-card-bg-color);
  box-shadow: 0 0 10px var(--light-sand);
  border-radius: 8px;
}
.menu-item-image-block {
  background: var(--white);
  margin: 10px;
  position: relative;
  left: -30px;
  border-radius: 10px;
  border: 1px solid var(--light-green);

  .menu-item-image {
    height: 80px;
    width: 80px;
    left: 40px;
  }
}
.menu-item-text-block {
  display: flex;
  padding: 20px;
}
