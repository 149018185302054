$mobile: 320px;
$mediumTablet: 767px;
$largeTablet: 1024px;
$desktop: 1399px;

.bg-login-register {
  background: url("../../assets/images/LoginPageBgImage.png");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  min-height: 100vh;
  overflow: auto;

  .pt-login-logo-block {
    padding: 24px 0 8px;

    @media screen and (min-width: $mobile) and (max-width: $largeTablet) {
      text-align: center;
    }

    .official-logo {
      width: 160px;
    }
  }
  .width-100 {
    width: 100%;
  }
  .main-component {
    padding: 50px 40px 0px !important;
  }
  .align-center {
    align-items: center;
  }
  .MuiBox-root {
    padding: 16px 24px !important;
  }
  .mt-1 {
    margin-top: 1em;
  }

  .pt-lr-mobileContainer {
    padding: 0px 80px;
    justify-content: center;

    @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
      padding: 0px 20px;
    }

    .pt-lr--project-block {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .pt-lr--project {
        padding: 0 24px;
        margin-top: 40px;
        width: 80%;
        border-width: 0px;
        border-left: 10px solid;
        border-image: linear-gradient(
            to bottom,
            var(--light-green),
            var(--light-green--2)
          )
          1 100%;

        @media screen and (min-width: $mobile) and (max-width: $largeTablet) {
          width: 100%;
          padding: 0 48px 0 24px;
          margin-left: 0px;
        }

        .pt-lr-project-name {
          font-family: "SiemensSans-Black", sans-serif;
          font-size: 2rem;
          line-height: 40px;
          color: var(--dark-blue);
          margin: 0;

          @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
            font-size: 1.5rem;
            line-height: 30px;
          }
        }

        .pt-lr--project-description {
          font-size: 1.125rem;
          display: block;
          margin: 16px 0 0;
        }
      }
    }

    .pt-lr-master-block {
      .pt-lr-block {
        padding: 24px 40px;
        box-shadow: 0 0px 10px var(--light-grey);
        background-color: var(--white);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (min-width: $mobile) and (max-width: $largeTablet) {
          margin: 40px 0;
        }

        .pt-login-siemens_user {
          h3 {
            margin: 0;
          }

          .myid-login-btn-block {
            margin: 16px 0 0;
          }
        }

        .pt-divider {
          padding: 8px 0;

          hr {
            border-top: 3px dashed var(--light-grey);
            border-bottom: none;
          }
        }

        .pt-login-external_user {
          h3 {
            margin: 0;
          }

          .pt-login-form {
            margin: 16px 0 0;

            .pt-login-form-input {
              width: 100%;
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}
